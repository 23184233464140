
<template>
    <div class="details-content">
        <!-- 康复管理音乐添加  -->
        <el-button class="back" size="small" plain @click="goBack">返回 <i class="el-icon-arrow-right"></i></el-button>
        <el-tabs v-model="activeName">
            <el-tab-pane :label="labelTitle" name="first" lazy>
               <div class="details-container">
                   <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                       <el-row>
                           <el-col :span="12">
                               <el-form-item label="新增标题" prop="msgName">
                                    <el-input v-model="ruleForm.msgName" maxlength="20" placeholder="请输入标题名称，20字以内"></el-input>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="12">
                               <el-form-item label="分类" prop="type_id">
                                   <el-select clearable filterable v-model="ruleForm.type_id" placeholder="请选择">
                                       <el-option v-for="(val,index) in typeList" :key="index" :label="val.title" :value="val.type_id"></el-option>
                                    </el-select>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24">
                            <el-form-item label="标题图片">
                                    <uoloadSingleImg 
                                       v-model="ruleForm.uploadPictures" 
                                       ref="uoloadSingleImg" 
                                       form="上传" 
                                       :uploadUrl="uploadUrl" 
                                       :uploadSize="2" 
                                       :fileList="fileList" 
                                       @childByValue="childByValue" 
                                       :tip="tip"
                                       :uoloadSingleStatus='uoloadSingleStatus'
                                    ></uoloadSingleImg>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24">
                            <el-form-item label="音乐文件" prop='music'>
                                    <uoloadSingleImg 
                                       v-model="ruleForm.music" 
                                       ref="uoloadSingleImg" 
                                       form="上传" 
                                       :uploadUrl="uploadUrlMusic" 
                                       :uploadSize="20" 
                                       :fileList="fileListMusic" 
                                       @childByValue="childByValue" 
                                       :tip="tipMusic" 
                                       :danger=true 
                                       :uoloadSingleType="'music'"
                                       :uoloadSingleStatus='uoloadSingleStatus'
                                    ></uoloadSingleImg>
                                    <audio :src="ruleForm.music" controls="controls" v-if="ruleForm.music!=''"></audio>
                                </el-form-item>
                           </el-col>
                       </el-row>
                       <el-row>
                           <el-col :span="24" class="text-left">
                                <el-button type="success"   @click="submitForm('ruleForm')" :loading="loading">{{loading?'保存中':'确定'}}</el-button>
                           </el-col>
                       </el-row>
                   </el-form>
               </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import uoloadSingleImg from "../../components/uploadSingleImg";
import { UploadFoundImage } from "../../api/api";//图片
import { UploadFoundMusic } from "../../api/api";//音乐
import { AddFoundDetailMusic } from "../../api/api";//新增
import { EditFoundDetailMusic } from "../../api/api";//修改
import { FoundDetailMusicInfo,MusicType } from "../../api/api";//详情
export default {
  components: {
    uoloadSingleImg
  },
    data() {
        return {
            lineId: this.$route.query.lineId, //康复行id 
            detailId: this.$route.query.detailId, //详情行id 
            lineType: this.$route.query.lineType, //类型   2为音乐
            activeName: "first",
            defaultMsg: "", //富文本默认值
            config: {
                initialFrameWidth: null,
                initialFrameHeight: 350
            },
            ruleForm:{
                msgName:'',//新增标题
                uploadPictures:'',//标题图片
                music:'',//音乐
                type_id:''
            },
            rules:{
                msgName: [{ required: true, message: "请输入新增标题", trigger: "change" }],
                type_id: [{ required: true, message: "请选择分类", trigger: "change" }],
                music: [{ required: true, message: "请上传音乐文件", trigger: "change" }],
            },
            typeList:[],
            tip:'请上传300*180尺寸的图片', //上传提示信息
            fileList:[],//默认显示图片
            uploadUrl:"",//上传单一图片地址
            tipMusic:'请上传20M以内的音乐文件', //上传提示信息
            uploadUrlMusic:'',//上传音乐地址
            fileListMusic:[],//默认显示音乐
            uoloadSingleStatus:'',
            labelTitle:'',//
            loading: false,
        };
    },
    created(){
         this.uploadUrl=UploadFoundImage;
         this.uploadUrlMusic=UploadFoundMusic;
         if(this.detailId!=''){//详情
            this.labelTitle='编辑';
            this.uoloadSingleStatus='edit';
                var db = { id: this.detailId };
                this.$axios.post(FoundDetailMusicInfo, db).then(
                res => {
                    if (res.data.code == 1) {
                         this.ruleForm.msgName = res.data.data.title;
                         this.ruleForm.type_id = res.data.data.type_id;
                        //  图片
                        this.fileList = res.data.data.img;
                        var imgUrl = [];
                        if(this.fileList.length>0){
                            for (var i = 0; i < this.fileList.length; i++) {
                                imgUrl.push(this.fileList[i].url);
                            }
                        }
                        this.ruleForm.uploadPictures = imgUrl.join(",");
                        // 音频
                        this.fileListMusic = res.data.data.music;
                        var musicUrl = [];
                        if(this.fileListMusic.length>0){
                            for (var i = 0; i < this.fileListMusic.length; i++) {
                                musicUrl.push(this.fileListMusic[i].url);
                            }
                        }
                        this.ruleForm.music = musicUrl.join(",");
                    } else {
                        this.$message.error(res.data.msg);
                    }
                },
                err => {
                this.$message.error(err.msg);
                }
            );
         }else{ //新增
            this.labelTitle='新增'
            this.uoloadSingleStatus='add'
         }
        // 分类
        this.common.communalApi.getTags(
        { url: MusicType, db: {  }, name: "typeList" },
        this.callback
        );
    },
    methods:{
        callback(db, ops) {
          this.$set(this, ops.name, db);
        },
        goBack(){
            this.$router.push({ name: "contentRecoveryCheck" ,query:{lineId:this.lineId,lineType:this.lineType}});
        },
        submitForm(formName) { //保存
            this.$refs[formName].validate((valid) => {
            if (valid) {
               var postUrl;
               var db; 
               if(this.detailId==''){ //新增
                   postUrl=AddFoundDetailMusic;
                   db={
                        title	:this.ruleForm.msgName,//	标题
                        img	:this.ruleForm.uploadPictures,//	图片地址
                        music	:this.ruleForm.music,//	音乐
                        line_id	:this.lineId,//	上级康复记录id
                        type_id	:this.ruleForm.type_id,
                   }
               }else{ //修改
                    postUrl=EditFoundDetailMusic;
                    db={
                        title	:this.ruleForm.msgName,//	标题
                        img	:this.ruleForm.uploadPictures,//	图片地址
                        music	:this.ruleForm.music,//	音乐
                        id	:this.detailId,//	康复记录id
                        type_id	:this.ruleForm.type_id,
                    }
               }
                this.$axios.post(postUrl, db).then(
                    res => {
                        this.loading = true;
                        if (res.data.code == 1) {
                          this.loading = false;
                          this.goBack();
                        } else {
                            this.loading = false;
                            this.$message.error(res.data.msg);
                        }
                    },
                    err => {
                        this.$message.error(err.msg);
                    }
               );
            } else {
                console.log('error submit!!');
                return false;
            }
            });
      },
      childByValue(val,type){
         this.fileList = [val];
         if(type.substr(-5)=='Music'){
            this.ruleForm.music = val;
         }
         else{
            this.ruleForm.uploadPictures = val;
         }
      },
    },
}
</script>

<style lang="scss">
</style>
